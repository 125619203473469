
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import tasqsListModule from '@/store/modules/tasqsListModule';
import tasqProductionDataChartModule from '@/store/modules/tasqProductionDataChartModule';

import 'vue-slider-component/theme/antd.css';
import { Debounce } from 'vue-debounce-decorator';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import assetsModule from '@/store/modules/assetsModule';
import tasqSignalsModule from '@/store/modules/tasqSignalsModule';

import {
  lightningChart,
  OnScreenMenuButtonType,
  OnScreenMenuButtonShape,
  Themes,
  ColorRGBA,
  ColorHEX,
  SolidFill,
  PointShape,
  SolidLine,
  AxisTickStrategies,
  AxisScrollStrategies,
  LegendBoxBuilders,
  AutoCursorModes,
  LegendBox,
  MouseClickEventType,
  MouseEventHandler,
  UILUTCheckBox,
  emptyLine,
  emptyFill,
  ColorCSS,
  customTheme,
  FunnelChartWithLabelsOnSides,
} from '@arction/lcjs';
// Extract required parts from XYData Generator.
import {
  createProgressiveTraceGenerator,
  createOHLCGenerator,
  createProgressiveRandomGenerator,
} from '@arction/xydata';
import { xgcdDependencies } from 'mathjs';
import { getConfigEnv } from '@/utils/helpers';

@Component({
  components: {},
})
export default class TasqLightningChart extends Vue {
  @Prop({ type: Boolean, required: false, default: false }) isFullScreen?: boolean;

  @Prop({ type: String, required: false, default: '' }) activeWellName?: string;

  @Prop({ type: Number, required: false, default: 60 }) activeChartTimes?: number;

  chart: any = null;

  showChart = true;

  chartId: any = null;

  chartsLoading = false;

  isChartLoaded = false;

  loadedSignals: any = [];

  points = [];

  created() {
    // setTimeout(() => {
    //   if (this.currentSignals && this.currentSignals.length) {
    //     this.updateChartToMatchLegend();
    //   }
    // },5000);
  }

  reloadChartOnScroll() {
    this.chart.engine.layout();
  }
  refresh() {
    this.chart.setTitle(' ');
    this.chart.setTitle('');
  }

  didSelectResetChart() {
    this.chart.getDefaultAxisX().release();
    this.chart.getDefaultAxisY().release();
    this.axisY.release();
    this.axisY2.release();
    this.axisY3.release();
    // this.resetSelectedSignals()
    // this.axisY4.release();
  }


  get productionType(){
    return  getConfigEnv('PRODUCTION_TYPE')
  }

  get legendSignals(): any {
    const legend_signals: any = [];
    for (let x = 0; x < this.currentSignals.length; x++) {
      legend_signals.push({
        name: this.currentSignals[x].name,
        selected: this.isSignalSelected(this.currentSignals[x].name),
        color: this.currentSignals[x].color,
        type: 'SIGNAL',
      });
    }


	  for (const [key, value] of Object.entries(this.productionDataDict)) {
      if (key == 'date' || value == null || value == undefined || key == 'nodeid') {
        continue;
      }

		const allowedKeys = ['water_rate','gas_rate' ,'oil_rate']
		if(this.productionType === 'boe'){
			allowedKeys.push('boe_rate')
		}

		if(!allowedKeys.includes(key)){
			continue;
		}
      let color = '';
      if (key == 'water_rate') {
        color = '#0077ff';
      } else if (key == 'gas_rate') {
        color = '#f55d8b';
      } else if (key == 'oil_rate') {
        color = '#2de6c1';
      }
      legend_signals.push({
        name: key,
        selected: this.isSignalSelected(key),
        color,
        type: 'PRODUCTION',
      });
    }

    return legend_signals;
  }

  getSignalIndex(signal_name) {
    for (let x = 0; x < this.chartSeries.length; x++) {
      const check_signal = signal_name.replace('_', ' ');
      if (this.chartSeries[x].Uc.toLowerCase() == check_signal.toLowerCase()) {
        return x;
      }
    }
    return -1;
  }


  async updateChartToMatchLegend() {
	  for (let a = 0; a < this.legendSignals.length; a++) {
	    if (this.chartSeries[this.getSignalIndex(this.legendSignals[a].name)] && tasqSignalsModule.selectedPlungerSignals.indexOf(this.legendSignals[a].name) > -1) {
	      this.chartSeries[this.getSignalIndex(this.legendSignals[a].name)].restore();
	    } else if( this.chartSeries[this.getSignalIndex(this.legendSignals[a].name)]) {
	      this.chartSeries[this.getSignalIndex(this.legendSignals[a].name)].dispose();
	    }
	  }
	}

  // async updateChartToMatchLegend() {
  //   for (let a = 0; a < this.legendSignals.length; a++) {
  //     if (
  //       tasqSignalsModule.selectedPlungerSignals.indexOf(this.legendSignals[a].name) > -1 &&
  //       this.chartSeries[this.getSignalIndex(this.legendSignals[a].name)]
  //     ) {
  //       this.chartSeries[this.getSignalIndex(this.legendSignals[a].name)].restore();
  //     } else if (
  //       this.getSignalIndex(this.legendSignals[a].name) &&
  //       this.chartSeries[this.getSignalIndex(this.legendSignals[a].name)]
  //     ) {
  //       this.chartSeries[this.getSignalIndex(this.legendSignals[a].name)].dispose();
  //     }
  //   }
  // }

  async initializePage(index, signal) {
    for (let x = 0; x < this.chartSeries.length; x++) {
      const check_signal = signal.replace('_', ' ');
      if (this.chartSeries[x].Uc.toLowerCase() == check_signal.toLowerCase()) {
        if (this.chartSeries[x].isDisposed()) {
          this.chartSeries[x].restore();
        } else {
          this.chartSeries[x].dispose();
        }
      }
    }
  }

  get defaultSelectedSignals(){
    return tasqSignalsModule.defaultSelectedPlungerSignals
  }
  resetSelectedSignals() {
    const signals = this.defaultSelectedSignals
    for (let x = 0; x < this.chartSeries.length; x++) {
      const selectedSignals = signals.map(s => s.replace('_', ' ').toLowerCase())
      if (selectedSignals.includes(this.chartSeries[x].Uc.toLowerCase())) {
        if (this.chartSeries[x].isDisposed()) {
          this.chartSeries[x].restore();
        }
      }else{

        this.chartSeries[x].dispose();
      }
    }
    tasqSignalsModule.setSelectedSignals(JSON.parse(JSON.stringify(this.defaultSelectedSignals)));
  }

  get isEditing() {
    return tasqsListModule.isEditing;
  }

  get signalDescriptions(): any {
    return tasqSignalsModule.plungerSignalDescriptions;
  }

  get currentSignals(): any {
    return tasqSignalsModule.currentPlungerSignals;
  }

  isSignalSelected(signal_name) {
    return tasqSignalsModule.selectedPlungerSignals.indexOf(signal_name) > -1;
  }

  storedLinesSeries: any = [];

  dateOrigin;

  legend;

  axisY;

  axisY2;

  axisY3;

  axisY4;

  signalsAdded: any = [];

  createChart() {
    const themeTextFillStyle = new SolidFill({ color: ColorCSS('#ffffff') });
    const themeDataSeriesFillStyles = [
      new SolidFill({ color: ColorCSS('#00FFEA') }),
      new SolidFill({ color: ColorCSS('#F21688') }),
      new SolidFill({ color: ColorCSS('#FFFF5D') }),
      new SolidFill({ color: ColorCSS('#FFCD5C') }),
      new SolidFill({ color: ColorCSS('#FFC8A5') }),
      new SolidFill({ color: ColorCSS('#FF94B8') }),
      new SolidFill({ color: ColorCSS('#DB94C6') }),
      new SolidFill({ color: ColorCSS('#A994C6') }),
      new SolidFill({ color: ColorCSS('#94B0C6') }),
      new SolidFill({ color: ColorCSS('#94E2C6') }),
      new SolidFill({ color: ColorCSS('#94FFB0') }),
      new SolidFill({ color: ColorCSS('#94ffdf') }),
      new SolidFill({ color: ColorCSS('#94eaff') }),
      new SolidFill({ color: ColorCSS('#94c1ff') }),
      new SolidFill({ color: ColorCSS('#a894ff') }),
      new SolidFill({ color: ColorCSS('#ffb194') }),
      new SolidFill({ color: ColorCSS('#90e64e') }),
      new SolidFill({ color: ColorCSS('#e64e4e') }),
      new SolidFill({ color: ColorCSS('#b14ee6') }),
      new SolidFill({ color: ColorCSS('#41c459') }),
      new SolidFill({ color: ColorCSS('#41c4b0') }),
    ];

    const themeAxisFillStyle = new SolidFill({ color: ColorCSS('#ffffff') });
    const themeMajorTickFillStyle = new SolidFill({ color: ColorCSS('#ffffff') });
    const themeMinorTickFillStyle = new SolidFill({ color: ColorCSS('#ffffff') });
    const themeMajorGridlineFillStyle = new SolidFill({ color: ColorCSS('#00000032') });
    const themeMinorGridlineFillStyle = new SolidFill({ color: ColorCSS('#00000014') });
    const themeUiBackgroundFillStyle = new SolidFill({ color: ColorCSS('rgba(120,120,120,0.5)') }); // Legend color
    const themeUiBackgroundBorderFillStyle = new SolidFill({ color: ColorCSS('#2b2b2b') });
    const themeCursorGridlineFillStyle = new SolidFill({ color: ColorCSS('#ffffff') });
    // darkMagenta
    const myTheme = customTheme(Themes.cyberSpace, {
      lcjsBackgroundFillStyle: new SolidFill({ color: ColorCSS('#00000000') }),
      panelBackgroundFillStyle: new SolidFill({ color: ColorCSS('#00000000') }),
      seriesBackgroundFillStyle: new SolidFill({ color: ColorCSS('#00000000') }),
      chartTitleFillStyle: themeTextFillStyle,
      axisTitleFillStyle: themeTextFillStyle,
      axisStyle: new SolidLine({ thickness: 1, fillStyle: themeAxisFillStyle }),
      numericTickStrategy: Themes.lightNew.numericTickStrategy
        .setMajorTickStyle((majorTicks) =>
          majorTicks
            .setLabelFillStyle(themeTextFillStyle)
            .setTickStyle(new SolidLine({ thickness: 1, fillStyle: themeMajorTickFillStyle }))
            .setGridStrokeStyle(new SolidLine({ thickness: 1, fillStyle: themeMajorGridlineFillStyle }))
        )
        .setMinorTickStyle((minorTicks) =>
          minorTicks
            // @ts-ignore
            .setLabelFillStyle(themeTextFillStyle)
            .setTickStyle(new SolidLine({ thickness: 1, fillStyle: themeMinorTickFillStyle }))
            .setGridStrokeStyle(new SolidLine({ thickness: 1, fillStyle: themeMinorGridlineFillStyle }))
        ),
      seriesFillStyle: (i) => themeDataSeriesFillStyles[i % themeDataSeriesFillStyles.length],
      seriesStrokeStyle: (i) =>
        new SolidLine({ thickness: 1, fillStyle: themeDataSeriesFillStyles[i % themeDataSeriesFillStyles.length] }),
      uiBackgroundFillStyle: themeUiBackgroundFillStyle,
      uiBackgroundStrokeStyle: new SolidLine({ thickness: 1, fillStyle: themeUiBackgroundBorderFillStyle }),
      uiTextFillStyle: themeTextFillStyle,
      resultTableFillStyle: themeUiBackgroundFillStyle,
      resultTableStrokeStyle: new SolidLine({ thickness: 1, fillStyle: themeUiBackgroundBorderFillStyle }),
      resultTableTextFillStyle: themeTextFillStyle,
      customTickGridStrokeStyle: new SolidLine({ thickness: 1, fillStyle: themeCursorGridlineFillStyle }),
      uiPointableTextBoxFillStyle: themeUiBackgroundFillStyle,
      uiPointableTextBoxStrokeStyle: new SolidLine({ thickness: 1, fillStyle: themeUiBackgroundBorderFillStyle }),
      uiPointableTextBoxTextFillStyle: themeTextFillStyle,
      pointMarkerFillStyle: new SolidFill({ color: ColorCSS('#ffffff') }),
      chartXYZoomingRectangleFillStyle: new SolidFill({ color: ColorCSS('#ffffff16') }),
      chartXYZoomingRectangleStrokeStyle: new SolidLine({
        thickness: 1,
        fillStyle: new SolidFill({ color: ColorCSS('#4f4f4f') }),
      }),
      chartXYFittingRectangleFillStyle: new SolidFill({ color: ColorCSS('#ffffff16') }),
      chartXYFittingRectangleStrokeStyle: new SolidLine({
        thickness: 1,
        fillStyle: new SolidFill({ color: ColorCSS('#4f4f4f') }),
      }),
    });

    const license = `0001-ff5ad4e054f80f2dd0977e5194ea2c7804dcae2d8fc19d352bb53ce081d1e1c8cc21be14a8b691660e1133da1d4d73813c2b4d760ddc385f32cd859fc3f6b7ed664894c62e4fa717fea588bb829429c8f7f9218a4f1f6684ca663999e246-2acb5316c96ce6435be3182ea4ee94db-30450220319f968bebc89be5d0ac8cb5b1903e4e023a07525c1b84d8aed5136bb0667a82022100f3ad7a24bde5f7427f10f3c1367aff4708fad5507d937da7e75220d5e29578d9`;

    if (location.href.includes('tasq.io')) {
      this.chart = lightningChart(license)
        .ChartXY({ container: `${this.chartId}`, theme: myTheme })
        .setTitle('');
    } else {
      this.chart = lightningChart()
        .ChartXY({ container: `${this.chartId}`, theme: myTheme })
        .setTitle('');
    }

    this.chart.setAnimationsEnabled(false);
    this.chart.setMouseInteractionWheelZoom(false);

    this.axisY = this.chart.getDefaultAxisY().setTickStrategy(AxisTickStrategies.Numeric, (ticks) => ticks
		.setFormattingFunction((value) => value.toFixed(1).toString() ));
    this.axisY2 = this.chart.addAxisY({
      opposite: true,
    }).setTickStrategy(AxisTickStrategies.Numeric, (ticks) => ticks
		.setFormattingFunction((value) => Math.floor(value).toString()));

    // this.axisY4 = this.chart.addAxisY({
    //   opposite: true,
    // });

    this.axisY3 = this.chart
      .addAxisY({})

      // Hide tick grid-lines from second Y axis.
      .setTickStrategy(AxisTickStrategies.Numeric, (ticks) =>
        ticks
        .setFormattingFunction((value) => Math.floor(value).toString())
          .setMinorTickStyle((minor) => minor.setGridStrokeStyle(emptyLine))
          .setMajorTickStyle((major) => major.setGridStrokeStyle(emptyLine))
      );

    const d = new Date();
    d.setDate(d.getDate() - tasqsListModule.signalChartTime);
    this.dateOrigin = d;
    this.chart
      .getDefaultAxisX()
      .setTickStrategy(AxisTickStrategies.DateTime, (tickStrategy) => tickStrategy.setDateOrigin(this.dateOrigin));
  }

  chartSeries: any = [];

  addSignalToChart(newSignal) {
    // @ts-ignore

    const resultInMinutes = 5;
    const signal_data: any = [];

    const previous_non_null_val = 0;

    for (let signal_y = 0; signal_y < newSignal.dataset.length; signal_y++) {
      let val = parseFloat(newSignal.dataset[signal_y]);
      let time = new Date(newSignal.time[signal_y]).getTime() - this.dateOrigin.getTime();

      // @ts-ignore
      signal_data.push({
        // @ts-ignore
        x: time,
        // @ts-ignore
        y: val,
      });
    }

    let axis_to_use = this.axisY2;
    if (newSignal.axisName) {
      axis_to_use = this[newSignal.axisName];
    }
    // Add line series to the chart
    const lineSeriesSignals = this.chart
      .addPointSeries({
        yAxis: axis_to_use,
        pointShape: PointShape.Circle,
      })
      .setPointFillStyle(new SolidFill({ color: ColorHEX(newSignal.color) }))
      .setPointFillStyleHighlight((solidFill) => solidFill.setA(80))
      .setPointSize(3);
    this.chartSeries.push(lineSeriesSignals);

    //   lineSeriesSignals.setStrokeStyle(new SolidLine({
    //     thickness: 1,
    //     fillStyle: new SolidFill({ color: ColorHEX(newSignal.color) }),
    //   }));
    // Set stroke style of the line
    //   lineSeriesSignals.setStrokeStyle((style) => style.setThickness(1));
    lineSeriesSignals.setName(newSignal.name);
    // Add data points to the line series
    lineSeriesSignals.add(signal_data);
    if (!this.isSignalSelected(newSignal.name)) {
      lineSeriesSignals.dispose();
    }
  }

  setSeriesVisibility() {
    // this.chartSeries
  }

  addDefaultSignalToChart() {
    // @ts-ignore
    const signal_data: any = [];

    signal_data.push({
        // @ts-ignore
        x: 0,
        // @ts-ignore
        y: 0,
      });

    let axis_to_use = this.axisY;

    // Add line series to the chart
    const lineSeriesSignals = this.chart.addLineSeries({
      yAxis: axis_to_use,
    });
    this.chartSeries.push(lineSeriesSignals);


    lineSeriesSignals.setStrokeStyle((style) => style.setThickness(1.5));
    lineSeriesSignals.setName('');
    // Add data points to the line series
    lineSeriesSignals.add(signal_data);
  }

  @Watch('isChartLoaded')
  @Watch('currentSignals')
  updateChartSignals(data) {
    if (this.currentSignals.length - 1 < 0) {
      return;
    }


    if (!this.loadedSignals.length && !this.isFullScreen) {
      tasqSignalsModule.resetSelectedPlungerSignals();
    }
    this.currentSignals.forEach((signal) => {
      if (
        !this.loadedSignals.includes(signal.name) &&
        this.activeWellName === signal.wellName &&
        this.activeChartTimes === signal.daysLength
      ) {
        this.loadedSignals.push(signal.name);
        if (signal.selected && !this.isSignalSelected(signal.name)) {
          tasqSignalsModule.selectPlungerSignal(signal.name);
        }
        this.addSignalToChart(signal);
      }
    });
    if(this.currentSignals.length && !this.isxAxisDefaultSet){
      this.isxAxisDefaultSet = true
      this.addDefaultSignalToChart()
    }

  }

  isxAxisDefaultSet = false

  didAddProductionData: any = {};

  beforeMount() {
    // Generate random ID to us as the containerId for the chart and the target div id
    this.chartId = Math.trunc(Math.random() * 1000000);
  }

  mounted() {
    // Chart can only be created when the component has mounted the DOM because
    // the chart needs the element with specified containerId to exist in the DOM

    this.createChart();

    if (this.isFullScreen) {
      for (let x = 0; x < this.currentSignals.length; x++) {
        this.addSignalToChart(this.currentSignals[x]);
      }

      for (const [key, value] of Object.entries(tasqProductionDataChartModule.productionDataDict)) {
        if (this.didAddProductionData[key] == undefined) {
          this.setupProductionData(this.dateOrigin);
          this.didAddProductionData[key] = key;
          break;
        }
      }
      this.updateChartToMatchLegend();
    }

    if (!tasqsListModule.isInitialPageLoad) {
	    if (Object.keys(tasqProductionDataChartModule.productionDataDict).length > 0) {
	      for (const [key, value] of tasqProductionDataChartModule.productionDataDictKeyValue) {
	        if (this.didAddProductionData[key] == undefined) {
	          this.setupProductionData(this.dateOrigin);
	          this.didAddProductionData[key] = key;
	          break;
	        }
	      }
	    }
	  }

    this.isChartLoaded = true;
  }

  get didLoadAllPlungerSignals() {
    return tasqSignalsModule.didLoadAllPlungerSignals;
  }

  beforeDestroy() {
    // console.log(this.chart)
    // "dispose" should be called when the component is unmounted to free all the resources used by the chart
    this.chart.dispose();
    this.chart = null;
  }

  get productionDataDict() {
    return tasqProductionDataChartModule.productionDataDict;
  }

  @Watch('productionDataDict')
  updateChartProdSignals(data) {
    for (const [key, value] of Object.entries(tasqProductionDataChartModule.productionDataDict)) {
      if (this.didAddProductionData[key] == undefined) {
        this.setupProductionData(this.dateOrigin);
        this.didAddProductionData[key] = key;
        break;
      }
    }
  }

  setupProductionData( dateOrigin) {
    // Do a check if it's within the bounds

    const prodDataFrequency = 1000 * 60 * 60 * 24;
    for (const [key, value] of tasqProductionDataChartModule.productionDataDictKeyValue) {
      if (key == 'date' || value == null || value == undefined || key == 'nodeid' || key.toLowerCase() == 'target') {
        continue;
      }

      let day_count = 0;
      const check_date = new Date(this.dateOrigin.getTime());
      const new_signal_data: any = [];
      while (check_date < new Date(tasqProductionDataChartModule.productionDataDict.date[0])) {
        // @ts-ignore
        new_signal_data.push({
          // @ts-ignore
          x: day_count * 24 * 60 * 60 * 1000,
          // @ts-ignore
          y: 0,
        });

        check_date.setDate(check_date.getDate() + 1);
        day_count += 1;
      }

      const signal_data: any = [];
      let prod_count = 0;
      let previous_non_null_val = 0;
      for (let signal_y = 0; signal_y < tasqProductionDataChartModule.productionDataDict[key].length; signal_y++) {
        if (new Date(tasqProductionDataChartModule.productionDataDict.date[signal_y]) < dateOrigin) {
          continue;
        }

        // var val = parseFloat(tasqProductionDataChartModule.productionDataDict[key][signal_y])
        // if (isNaN(val)) {
        // 	val = Number.NaN
        // }

        let val = parseFloat(tasqProductionDataChartModule.productionDataDict[key][signal_y]);
        if (isNaN(val)) {
          val = previous_non_null_val;
        } else {
          previous_non_null_val = val;
        }

        // @ts-ignore
        signal_data.push({
          // @ts-ignore
          x: (prod_count + day_count) * prodDataFrequency,
          // @ts-ignore
          y: val,
        });
        prod_count += 1;
      }

      let signalColor: any = ColorRGBA(245, 93, 139);
      if (key == 'oil_rate') {
        signalColor = ColorRGBA(45, 230, 193);
      }
      if (key == 'water_rate') {
        signalColor = ColorRGBA(0, 118, 255);
      }
      if (key == 'boe_rate') {
        signalColor = ColorRGBA(255, 217, 119);
      }

      // const signalAxis = key == 'oil_rate' || key == 'water_rate' ||  key == 'boe_rate' ? this.axisY3 : this.axisY;
      const signalAxis = this.axisY3

      // Add line series to the chart
      const lineSeriesSignals = this.chart.addLineSeries({
        yAxis: signalAxis,
      });
      lineSeriesSignals.setStrokeStyle(
        new SolidLine({
          thickness: 2,
          fillStyle: new SolidFill({ color: signalColor }),
        })
      );
      lineSeriesSignals.setName(key.replace('_', ' '));
      // Add data points to the line series
      lineSeriesSignals.add(signal_data);
      if (!this.isSignalSelected(key)) {
        lineSeriesSignals.dispose();
      }

      this.chartSeries.push(lineSeriesSignals);

      this.axisY.setTitle('Time / Duration');
      this.axisY2.setTitle('Pressure');
      this.axisY3.setTitle('Flowrate / Volume');
    }
  }
}
